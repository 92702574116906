// fullpage-setup.ts
import fullpage from 'fullpage.js';
import 'fullpage.js/dist/fullpage.css';

export function setupFullPage(marginTop: number = 0) {
  const fullPageMenu = document.getElementById('fullScreenMenu');
  let anchorItems = [];

  if (fullPageMenu) {
    const menuItems = fullPageMenu.querySelectorAll('li');
    for (let i = 0; i < menuItems.length; i++) {
      anchorItems.push(menuItems[i].getAttribute('data-menuanchor'));
    }
  }

  anchorItems.push('footer');
  const fullPageElement = document.getElementById('fullpage');
  const scheduleBlock = document.querySelector('#scheduleBlock');
  if (fullPageElement) {
    const fullPageInstance = new fullpage('#fullpage', {
      licenseKey: 'UL0KI-P518H-36TKK-096JK-XVLWQ',
      credits: false,
      menu: '#fullScreenMenu',
      recordHistory: true,
      lazyLoading: true,
      anchors: anchorItems,
      paddingTop: `${marginTop}px`,
      onLeave: function (origin, destination) {
        if (!scheduleBlock) {
          return;
        }
        if (!destination['isFirst']) {
          scheduleBlock.parentElement.classList.add('hide');
        }
      },
      afterLoad: function (origin, destination) {
        if (!scheduleBlock) {
          return;
        }
        if (destination['isFirst']) {

          scheduleBlock.parentElement.classList.remove('hide');

          // Fixing bug where fullpage.js pushes the scheduleBlock out of screen
          scheduleBlock.parentElement.style.position ='sticky';
          scheduleBlock.parentElement.style.zIndex ='1';
          scheduleBlock.parentElement.style.top ='0';
        }
      }
    });
  }
}
